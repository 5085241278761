@keyframes animateBackground {
  0% {
    background-image: url("https://oaa.missionscheduler.com/api/images/login-bg1.jpg");
  }
  33% {
    background-image: url("https://oaa.missionscheduler.com/api/images/login-bg2.jpg");
  }
  100% {
    background-image: url("https://oaa.missionscheduler.com/api/images/login-bg3.jpg");
  }
}

.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  animation: animateBackground 20s infinite;
  z-index: -1;
}

.login-content {
  position: relative;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}
